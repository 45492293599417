/* MAIN------------------------------------------CSS */
html{
    scroll-behavior:smooth
}

* {
    line-height: 1.5rem;
}
/* MAIN-------------------------------------------GRID */

/*  change to template area*/

.navbar-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto;
    grid-template-areas:
    " nav-logo nav-content "
      ;
}

.nav-logo{
    grid-area: nav-logo;
    margin-left: 16px;
}

.nav-content {
    grid-area: nav-content;
}

.grid-container{
        margin: 0 auto;
        width: 70%;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 100px auto;
        grid-template-areas:
        " hd "
        " main "
          ;
}

.grid-item-header {
    grid-area: hd;
}

.grid-item-main {
    grid-area: main;
}


/* MAIN------------------------------------------NAVIGATION */

.navbar {
    background-color: black;
    height: 10%;
    position: fixed;
    top: 0;
    overflow: hidden;
    z-index: 100;
    transition: background-color .3s;
}

.nav-bar--hidden {
    background-color: rgba(0,0,0,0.5);
    color: 	rgba(255,255,255, 1);
    transition: background-color .3s;
}

.nav-link {
    text-decoration: none;
    color: inherit;
}

.navbar-list {
    float: right;
    padding: 8px;
}

.nav-list-ul {
margin-right: 16px;
}

.nav-toggle-off {
    display: none;
}

/* MAIN--------------------------------------------------HEADER */

.header-home {
    text-decoration: none;
    color: white;
    font-size: 40px;
}

.icon-top-bar {
    background: url('../assets/logo.png');
    height: 40px;
    width: 40px;
    display: block;
    margin-left: 32px;
}

.header-home-text{
    text-decoration: none;
    font-size: 16px;
}

.icon-transition {
   color: black;
   transition: color .5s ;
}

.top-bar--hidden {
    display: none;
}

.top-bar--Show {
    display: block;
    color: white;
}

/* MAIN --------------------------------------------------HOME */

#Home {
    padding-top: 200px;
    padding-bottom: 150px;
    margin-bottom: 100px;
    background-color: black;
    position: relative;
}

.page-head {
    text-align: center;
}

.sub-header {
    display: block;
    text-align: center;
}


.profilePic {
    height: 320px;
    border-radius: 5px;
    margin: 0 auto;
}

.tech-list {
    list-style-type: none;
}

.icon {
    background: url('../assets/logo.png');
    height: 150px;
    width: 150px;
    display: block;
    margin: 0 auto;
    /* Other styles here */
}

body {
    background: white;
  }
  
  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .mouse_scroll {
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 100px;
    margin-top: 125px;
  }
  
  .m_scroll_arrows {
    display: block;
    width: 5px;
    height: 5px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    margin: 0 0 3px 4px;
  
    width: 16px;
    height: 16px;
  }
  
  .first {
    margin-top: 1px;
  }
  
  .first,
  .second,
  .third {
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
  }
  
  .first {
    -webkit-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    -webkit-animation-direction: alternate;
  
    animation-direction: alternate;
    animation-delay: alternate;
  }
  
  .second {
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    -webkit-animation-direction: alternate;
  
    animation-delay: 0.2s;
    animation-direction: alternate;
  
    margin-top: -6px;
  }
  
  .third {
    -webkit-animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
    -webkit-animation-direction: alternate;
  
    animation-delay: 0.3s;
    animation-direction: alternate;
  
    margin-top: -6px;
  }
  
  .mouse {
    height: 42px;
    width: 24px;
    border-radius: 14px;
    transform: none;
    border: 2px solid white;
    top: 170px;
  }
  
  .wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: white;
    position: relative;
  
    height: 4px;
    width: 4px;
    border: 2px solid #fff;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }
  
  @-webkit-keyframes mouse-wheel {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: translateY(6px);
      -ms-transform: translateY(6px);
      transform: translateY(6px);
    }
  }
  
  @-webkit-keyframes mouse-scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes mouse-scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes mouse-scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes mouse-scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

/* MAIN -------------------------------------------------ABOUT */

#About {
    margin-top: 150px;
    background-color: white;
    color: black;
    height: 300px;
}

.about-container {
    padding: 24px 0;
}

.profilePic {
    height: 320px;
    border-radius: 5px;
    margin: 0 auto;
}

.tech-list {
    list-style-type: none;
}

.about-margin {
    margin: 0 16px;
}

.about-header {
    margin-top: 50px;
    margin-bottom: 24px;
}

/* MAIN --------------------------------------------PROJECTS */

#Project {
    background-color: white;
    color: black;
    padding: 0;
}

.projects-header {
    margin: 0 0 16px 16px;
    margin-top: 50px;
    margin-bottom: 24px;
}

.projects-header-wrapper {
    width: 90%;
}

.header-wrapper {
    background-color: white;
}

.card-wrapper {
    width: 90%;
}

.card-content {
    padding: 8px;
    border-left: 1px solid rgb(241, 241, 241);

}

.card-title {
    margin: 0;
    font-size: 16px;
}

.project-card {
    display: block;
    float: left;
    max-width: 200px;
    margin: 16px;
}

.project-card:hover {
    border-radius: 5px;
    box-shadow: 7px 7px rgb(231, 231, 231);
    background-color: rgb(250, 250, 250); 
    transition: box-shadow .5s, background-color .7s, rotate .5s;
}

h2 {
    color: black;
}

.project-link {
    color: inherit;
}

/* MAIN ------------------------------------------LANDING */

#Landing {
    margin-top: 50px;
    margin-bottom: 50px;
    color: black;
}

.btn-anchor {
    color: white;
    width: 20%;
    margin: 0 auto;
}

.btn {
    margin-top: 16px;
    color: black;
    padding: 8px;
    margin: 8px;
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
}

.btn:hover {
    background-color: black;
    color: white;
    transition: color .5s, background-color .5s;
}

.btn-text {
    margin: 8px
}

/* MAIN ----------------------------- SKILLS */

#Skills {
    background-color: white;
    color: black;
}

.skills-header {
    margin: 24px 0 16px 16px;
    margin-top: 50px;
    margin-bottom: 24px;
    padding-right: 0px
}

li {
    font-style: inherit;
    list-style-type: none;
}

.skills-sub-header {
    margin: 16px 0 16px 16px;
    font-size: 24px;
}

.skill-list {
    margin-left: 16px;
    padding: 0;
}

/* MAIN ------------------------------------ RESUME */

#Resume {
    padding: 0;
    background-color: black;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resume-wrapper{
    margin: 75px 0 0;
}

.resume-anchor {
    text-decoration: none;
    color: white;
    width: 20%;
    margin: 0 auto;
}

.resume-link {
    display: block;
    padding: 16px 0;
    margin: auto;
    text-align: center;
    border: 2px solid white;
    border-radius: 5px;
}

.resume-link:hover {
    background-color: white;
    color: Black;
    transition: color .5s, background-color .5s;
}

/* MAIN -------------------------------------------------------- CONTACT */

#Contact {
    background-color: black;
}

.contact-header {
    color: white;
    margin: 0 auto;
    text-align: center;
    padding: 24px 0 0 0;
    border-top: 1px solid gray;
}

.form-style {
    display: block;
    width: 50%;
    margin: 0 auto;
    padding: 8px;
}

.form-label {
    margin: 0 auto;
    align-content: center;
    display: block;
}a

.button-wrapper {
    text-align: center;
    padding: 16px;
}

.submit-btn {
    text-decoration: none;
    color: white;
    background-color: black;
    display: block;
    padding: 16px 24px;
    margin: 24px auto;
    text-align: center;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 16px;
}

.submit-btn:hover {
    background-color: white;
    color: black;
    transition: color .5s, background-color .5s;
}

.submit-btn:active {
    color: black;
    background-color: white;
}

.error-text {
    color: red;
    text-align: center;
}

/* Main ---------------------------------------------------------FOOTER */

.footer-icon-wrapper {
    padding: 16px;
    display: flex;
    justify-content: center;
}

.footer-link{
    display: block;
    margin: 0 auto;
    size: 32px;
}

svg.defs-only {
    display:block; position: absolute; 
    height:0; width:0; margin: 0; padding: 0; 
    border: none; overflow: hidden;
}

/* MEDIA */

/* MEDIA-------------------------------------------------NAVBAR*/

    @media screen and (max-width: 360px){
        .navbar-grid {
            width: 100%;
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            grid-template-areas:
            " nav-logo nav-content "
              ;
        }

        .nav-content {
            background-color: black;
            height: auto;
        }

        .nav-list-ul {
            flex-direction: column;
            height: auto;
            background-color: black;
        }

        .nav-item {
            display: block;
            width: 100%;
            text-align: center;
            margin-right: 0px;
            height: 32px;
            margin: 16px;
            size: 16px;
            color: black;
        }

        .nav-link {
            color: white;
            background-color: black;
            z-index: 10000;
        }
       
    }

/* MEDIA ------------------------------------------HOME */

@media screen and (max-width: 680) {
    .icon-div {
        display: none;
    }

    .page-icon {
        display: none;
    }
}



/* MEDIA ------------------------------------------ABOUT */

@media screen and (max-width: 360px) {
    #About {
        margin-bottom: 550px;
    }

    .card-content {
        margin: 16px 16px 16px 0;
    }
}

@media screen and (min-width: 360px) {
    #About {
        margin-bottom: 400px;
    }

    .card-content {
        margin: 16px 16px 16px 0;
    }
}

@media screen and (min-width: 480px) {
    #About {
        margin-bottom: 250px;
    }

    .card-content {
        margin: 16px 16px 16px 0;
    }
}

@media screen and (min-width: 768px) {
    #About {
        margin-bottom: 100px;
    }
}

/* @media screen and (max-width: 680px) {
    .about-margin {
        padding-bottom: 300px;
        display: block;
    }
} */

/* MAIN --------------------------------------------PROJECTS */

@media screen and (max-width: 992px) {
    .projects-header {
        margin-top: 80px;
    }
}

@media screen and (max-width: 680px) {
    /* .projects-header-wrapper {
        margin-bottom: 500px;
    } */

    /* .projects-header {
        margin-top: 150px;
        margin-bottom: 150px;
    } */
    
}

@media screen and (max-width: 360px) {
    /* #Project {
        margin-top: 100px;
    } */
}

/* MEDIA ----------------------------------------------- RESUME */

